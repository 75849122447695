import React from 'react'
import UsedEquipmentTemplate from 'templates/used-equipment-template'
import { graphql } from 'gatsby'

const UsedEquipmentAllTemplate = props => {
  return <UsedEquipmentTemplate {...props} />
}

export const pageQuery = graphql`
  query UsedEquipmentAll {
    allMachineFinderEquipment(sort: { fields: [modifiedDate], order: DESC }) {
      nodes {
        engineHoursMeter
        horsepower
        id
        inBargainBin
        inHUT229
        initialPrice
        isNew
        location {
          name
          state
        }
        manufacturer
        meters {
          type
          value
        }
        model
        modelYear
        modifiedDate
        price
        primaryImage {
          large
          regular
        }
        reducedPrice
        separatorHoursMeter
        slug
        stockNumber
        title
      }
    }
  }
`

export default UsedEquipmentAllTemplate
